/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const usersList = module => {
  const basePath = `/navigation/${module.toLowerCase()}`;
  return {
    title: 'Users',
    ajaxURL: '/api/users/?format=json',
    addURLs: [
      {
        name: 'Create User',
        url: `${basePath}/users/new-user/`,
      },
    ],
    exportURL: '/users/export',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Phone', field: 'phone' },
      { title: 'Email', field: 'email' },
      {
        title: 'Type',
        formatter: function (cell) {
          return cell.getRow().getData().user_type.name;
        },
      },
      { title: 'Source', field: 'source_display' },
      {
        title: 'View',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success px-3"
            href="${basePath}/users/${cell.getRow().getData().id}/"
            >View</a>`;
        },
      },
    ],
  };
};

/**
 * Reuse partner data table list props
 **/
export const userBranchesList = () => {
  return {
    title: 'Branches',
    ajaxURL:
      '/api/organisation-branches/?format=json&fields=id,name,email_address,phone_number,url',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email_address' },
      { title: 'Phone', field: 'phone_number' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            target="_blank"
            href="${rowData.url}">View</a>`;
        },
      },
    ],
  };
};

/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const userKYCList = module => {
  const basePath = `/navigation/${module.toLowerCase()}/users`;
  return {
    title: 'User Document Attachment',
    ajaxURL:
      '/api/staff-document-attachment/?format=json&fields=id,title,description,content_type',
    columns: [
      { title: 'Document Name', field: 'title' },
      {
        title: 'Description',
        field: 'description',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Type',
        field: 'content_type',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Download',
        formatter: function (cell) {
          return `<a target="_blank" href="/staff/download_staff_attachment/${
            cell.getRow().getData().id
          }"><i class="fa fa-download" aria-hidden="true"></i></a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          return `<a href="${basePath}/update-user-document/${
            cell.getRow().getData().id
          }"><i class="fa-solid fa-edit text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sorter: false,
        formatter: function (cell) {
          return `<a href="${basePath}/delete-user-document/${
            cell.getRow().getData().id
          }"><i class="fa-solid fa-trash text-danger" aria-hidden="true"></i></a>`;
        },
      },
    ],
  };
};

export const userZonesList = () => {
  const basePath = `/navigation/setup`;
  return {
    title: 'Roles',
    ajaxURL: '/api/territory_users/',
    columns: [
      { title: 'Name', field: 'territory_name' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            target="_blank"
            class="btn btn-sm btn-success"
            href="${basePath}/territory/${rowData.territory.id}/">View</a>`;
        },
      },
    ],
  };
};

export const userStaffList = module => {
  const basePath = `/navigation/${module.toLowerCase()}/users`;
  return {
    title: 'Staff',
    ajaxURL: '/api/user_staff/',
    columns: [
      { title: 'Name', field: 'user_name' },
      { title: 'Email', field: 'user_email' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${basePath}/${rowData.user.id}">View</a>`;
        },
      },
    ],
  };
};
